@font-face {
  font-family: "HKGrotesk";
  font-weight: 700;
  src: local("HKGrotesk-Bold"), url(./fonts/HKGrotesk-Bold.otf) format("opentype");
}

@font-face {
  font-family: "HKGrotesk";
  font-weight: 600;
  src: local("HKGrotesk-SemiBold"), url(./fonts/HKGrotesk-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: "HKGrotesk";
  font-weight: 500;
  src: local("HKGrotesk-Medium"), url(./fonts/HKGrotesk-Medium.otf) format("opentype");
}

@font-face {
  font-family: "HKGrotesk";
  font-weight: 400;
  src: local("HKGrotesk"), url(./fonts/HKGrotesk-Regular.otf) format("opentype");
}

:root {
  --theme-very-light-brown: rgb(216, 175, 140);
  --theme-blue-grey: #9c9eb9;
  --theme-blue-grey-2: #8e91b5;
  --theme-light-blue-grey: #b8c2df;
  --theme-dark-1: #20243b;
  --theme-dark-2: #151827;
  --theme-dark-3: #2a2e4c;
  --theme-dark-4: #16192f;
  --theme-dark-5: #161929;
  --theme-dark-6: #222536;
  --theme-dusk: #3f466d;
  --theme-dusky-blue: #4855a3;
  --theme-robins-egg-blue: #80f4f8;
  --theme-steel: #6e7694;
  --theme-light-aquamarine: #76fadf;
  --theme-clear-blue: #1f87fe;
  --theme-greeny-blue: #3ec8bc;

  --theme-border-color: rgb(228, 228, 228, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  font-family: 'HKGrotesk', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: white;
  background-color: rgb(21, 24, 39);
}

h1, h2, h3, h4, h5, h6 {
  color: white;
  margin: 0; padding: 0;
}

.mk-map-view {
  min-height: 800px;
  min-width: 1200px;
  max-height: 800px;
  max-width: 1200px;
}

.video-js .vjs-play-progress {
  background-color: green;
}

.video-react .video-react-video{
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.video-react .video-react-big-play-button{
  display: none;
}

.video-react .video-react-play-progress {
  background-color: rgb(118, 250, 223);
  height: 8px !important;
}

.video-react-load-progress,
.video-react .video-react-progress-holder,
.video-react-play-progress .video-react-slider-bar{
  height: 8px !important;
}

.video-react .video-react-play-progress:before {
  font-size: 1.5rem;
  color: rgb(118, 250, 223);
}

.vertVideo > video {
  object-fit: contain;
  height: 50%;
}

.ant-modal {
  width: 471px !important;
}

.ant-modal-footer {
  border-top: none !important;
  text-align: center !important;
}
.ant-modal-footer > a {
  color: white;
  font-size: 16px;
}

.ant-modal-content {
  margin-top: 100px;
  width: 471px !important;
  height: 455px !important;
  background-color: rgb(40, 43, 73, 0.42);
}

.anonymous-button,
.apple-button,
.google-button {
  visibility: visible !important;
  background-size: cover !important;
  height: 40px;
  width: 217px;
  border: none !important;
  border-radius: 6px !important;
  width: 50% !important;
}
.google-button, .google-button:hover {
  margin-top: 113px !important;
  margin-bottom: 15px !important;
  background-image: url('/images/group4@3x.png');
}
.apple-button:hover,
.apple-button {
  background-image: url('/images/group3.png');

}
.anonymous-button:hover,
.apple-button:hover,
.google-button:hover {
  border: 1px solid rgb(40, 43, 73) !important;
  color: rgb(216, 175, 140);
}

.anonymous-button {
  background-color: rgb(216, 175, 140);
  color: white;
  font-size: 16px;
}